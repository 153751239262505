<template>
  <v-list-item
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    :to="item.to"
    active-class="third accent--text"
    link
    :class="itemClass"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-icon
      v-if="!item.icon"
      class="text-caption text-uppercase justify-center ml-1 my-2 align-self-center"
    >
      {{ title }}
    </v-list-item-icon>

    <v-list-item-avatar v-if="item.avatar">
      <v-img :src="item.avatar" />
    </v-list-item-avatar>

    <v-list-item-icon
      v-if="item.icon"
      class="my-2 align-self-center"
    >
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import { get } from 'vuex-pathify'
  export default {
    name: 'DefaultListItem',
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
      isSubItem: Boolean,
    },
    data () {
      return {
        itemClass: '',
      }
    },
    watch: {
      drawer (value) {
        this.itemClass = this.isSubItem ? (value ? 'py-1 pl-8' : 'py-1') : 'py-1'
      },
    },
    created () {
      this.itemClass = this.isSubItem ? 'py-1 pl-8' : 'py-1'
    },
    ...get('app', ['drawer', 'mini']),
    computed: {
      title () {
        const matches = this.item.title.match(/\b(\w)/g)
        return matches.join('')
      },
    },
  }
</script>
